<template>
	<!-- <div class="fixed-bottom bg-light small"> -->
	<div class="fixed-bottom bg-light">
		<b-nav pills fill>
			<!-- BUYER'S DASHBOARD -->
			<router-link class="nav-item nav-link" :to="toBuyerDashboard">
				<b-icon-house-door />
			</router-link>

			<!-- HOME -->
			<router-link class="nav-item nav-link" :to="{ name: 'StoreDashboard' }">
				<b-icon-bag />
			</router-link>

			<!-- CART -->
			<router-link class="nav-item nav-link" :to="{ name: 'Cart' }">
				<!-- <div class="d-flex flex-column">
          <div> -->
				<template v-if="badgeCount > 0">
					<div class="badge-wrapper">
						<b-icon-cart4 />
						<b-badge>{{ badgeCount }}</b-badge>
					</div>
				</template>
				<b-icon-cart4 v-else />
			</router-link>

			<!-- ORDERS -->
			<router-link class="nav-item nav-link" :to="{ name: 'StoreOrders' }">
				<b-icon-list-task />
			</router-link>
		</b-nav>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			store: "guest/store/getStore",

			// user
			token: "login/getToken",
			cartItems: "user/cartItems/getList",

			// guest
			guestCartItems: "guest/carts/getCartItems",

			// prescriptions
			cartPrescriptions: "user/userCartPrescriptions/getCartPrescriptions",
		}),
		badgeCount() {
			if (!this.token) {
				return this.guestCartItems.length;
			}
			return this.cartItems.length + this.cartPrescriptions.length;
		},
		toBuyerDashboard() {
			if (this.token) return { name: "BuyerDashboard" };
			return {
				name: "CheckUserCredentials",
				query: { redirect: "BuyerDashboard" },
			};
		},
	},
};
</script>

<style scoped>
.badge-wrapper {
	position: relative;
}
.badge-wrapper .badge {
	position: relative;
	top: -10px;
	right: 6px;
}
</style>
