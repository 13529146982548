<template>
	<div class="wrapper">
		<!-- Navbar -->
		<!-- <PostLoginNavbar /> -->

		<!-- <h1>Store Parent ({{ storeUrlName }} || {{ cartId }})</h1> -->

		<!-- Content -->
		<b-container fluid>
			<!-- AUTO LOGOUT -->
			<!-- <AutoLogout /> -->

			<div v-if="loading" class="text-center">
				<b-spinner class="m-5" label="Loading..."></b-spinner>
			</div>

			<Content v-else :data="store" :key="store ? store.id : null"></Content>
		</b-container>

		<!-- BOTTOM NAV -->
		<StoreBottomNavbar v-if="displayNavItems" />
	</div>
</template>

<script>
import { getCartItems } from "../../apis/user";

import StoreBottomNavbar from "../../components/navbars/store/StoreBottomNavbar";
import { mapActions, mapGetters } from "vuex";

export default {
	components: {
		StoreBottomNavbar,
	},
	created() {
		this.loading = true;
		const visitedStoreUrlName = this.$route.params.storeUrlName;
		this.initStore(visitedStoreUrlName)
			.then(() => {
				this.loading = false;
				this.displayNavItems = true;
				this.onSuccessfulStoreLoad();
			})
			.catch((err) => {
				this.loading = false;
				this.onFailureOfStoreLoad(err.data);
			});
	},
	data() {
		return {
			loading: false,
			displayNavItems: false,
		};
	},
	computed: {
		...mapGetters({
			token: "login/getToken",
			store: "guest/store/getStore",
			storeUrlName: "guest/store/getStoreUrlName",
		}),
	},
	methods: {
		...mapActions({
			initStore: "guest/store/init",

			// User
			// Store Visited
			storeVisited: "user/storeVisited",
			// User cart
			initCart: "user/cart/initCart",
			setCartItems: "user/cartItems/setList",
			// Usercart prescriptions
			getCartPrescriptionsApi: "user/userCartPrescriptions/getCartPrescriptions",

			// Guest cart
			setCurrentStore: "guest/carts/setCurrentStore",
			createStoreCart: "guest/carts/createStoreCart",
		}),
		onSuccessfulStoreLoad() {
			// init cart if logged in
			if (this.token) {
				// mark store visited
				this.storeVisited();
				this.createUserCart();
			} else {
				this.setCurrentStore(this.store);
				this.createStoreCart();
			}
		},
		onFailureOfStoreLoad(data) {
			this.errors = data.errors;

			if (this.errors.store) {
				this.$router.push({ name: "NoStoreFound" });
				return;
			}
			this.$router.push({ name: "NotFound" });
		},
		createUserCart() {
			const storeUrlName = this.storeUrlName;
			this.initCart({ storeUrlName }).then(() => {
				// cart initialized
				// also getting list of cart items, so that when products are loaded, we can already update the quantity
				getCartItems(storeUrlName).then((data) => {
					this.setCartItems(data);
				});

				// make api call to get cart prescription
				this.getCartPrescriptionsApi();
			});
		},
	},
};
</script>

<style scoped>
.container,
.container-fluid {
	margin-bottom: 75px;
}
</style>
